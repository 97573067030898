<script setup lang="ts">
import type { NuxtUiButtonUi } from "~/types/nuxt/ui/button";

const props = withDefaults(
  defineProps<{
    title?: string;
    description?: string;

    confirmButtonLabel?: string;
    rejectButtonLabel?: string;

    confirmButtonProps?: NuxtUiButtonUi;
    rejectButtonProps?: NuxtUiButtonUi;

    hideRejectButton?: boolean;
    preventClose?: boolean;
  }>(),
  {
    title: "Подтверждение",
    description: "Вы уверены?",

    confirmButtonLabel: "Да",
    rejectButtonLabel: "Нет",

    confirmButtonProps: {
      color: "info",
    },
    rejectButtonProps: {
      color: "info",
      variant: "ghost",
    },
  },
);

const emits = defineEmits<{
  (e: "confirm" | "reject"): void;
}>();

const showModalModel = defineModel<boolean>();

function onConfirm() {
  showModalModel.value = false;
  emits("confirm");
}

function onReject() {
  showModalModel.value = false;
  emits("reject");
}
</script>

<template>
  <Modal
    v-model="showModalModel"
    :title="props.title"
    :prevent-close="props.preventClose"
    @close="onReject"
  >
    <template #default>
      <slot>
        <div class="break-all">{{ props.description }}</div>
      </slot>
    </template>

    <template #footer>
      <UButton
        v-if="!props.hideRejectButton"
        :label="props.rejectButtonLabel"
        v-bind="props.rejectButtonProps"
        @click="onReject"
      />
      <UButton
        :label="props.confirmButtonLabel"
        v-bind="props.confirmButtonProps"
        @click="onConfirm"
      />
    </template>
  </Modal>
</template>

<style scoped></style>
